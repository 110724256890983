import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { AnimationBuilder, AnimationMetadata, style, animate, AnimationPlayer, keyframes } from '@angular/animations'

@Directive({
  selector: '[appAnimationOnScroll]'
})
export class AnimationOnScrollDirective {
  player: AnimationPlayer

  @Input() mode: string

  @Input() type: string

  state: boolean = false;

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    // if (this.player && !this.state) {
    //   this.player.destroy();
    // }

    const y = this.el.nativeElement.getBoundingClientRect().y;
    var scrollPosition = window.pageYOffset || document.documentElement.scrollTop;

    const componentPosition = y + scrollPosition
    scrollPosition = scrollPosition + screen.height*0.8

    // console.log('component: ' + componentPosition)
    // console.log('scroll: ' + scrollPosition)

    // console.log('state: ' +  this.state)

    if (scrollPosition >= componentPosition) {
      if(!this.state) {
        this.state = true
        const metadata = this.animateIn(this.mode, this.type)
        const factory = this.builder.build(metadata);
        this.player = factory.create(this.el.nativeElement);
        this.player.play();
      }
    } else {
      if(this.state) {
        this.state = false
        const metadata = this.fadeOut(this.mode)
        const factory = this.builder.build(metadata);
        this.player = factory.create(this.el.nativeElement);
        this.player.play();
      }
    }

  }

  constructor(private builder: AnimationBuilder, public el: ElementRef) {

  }

  private animateIn(mode: string, type: string): AnimationMetadata[] {
    if(type === 'zoonIn'){
      return [
        style({ opacity: 0, transform: 'translateX(-100px)'}),
        animate(mode, style({ opacity: 1, transform: 'translateX(0px)' })),
      ];
    }else if(type === 'zoomIn'){
      return [
        style({ opacity: 0, transform: 'scale(1)'}),
        animate(mode, keyframes([
          style({ opacity: 0, transform: 'scale(1)' }),
          style({ opacity: 0.5, transform: 'scale(1.2)' }),
          style({ opacity: 1, transform: 'scale(1)' })
        ]))
      ];
    }else if(type === 'zoomOut'){
      return [
        style({ opacity: 0, transform: 'scale(1)'}),
        animate(mode, keyframes([
          style({ opacity: 0, transform: 'scale(1)' }),
          style({ opacity: 1, transform: 'scale(0.8)' }),
          style({ opacity: 2, transform: 'scale(1)' })
        ]))
      ];
    }else if(type === 'fadeInTop'){
      return [
        style({ opacity: 0, transform: 'translateY(-200px)'}),
        animate(mode, keyframes([
          style({ opacity: 0, transform: 'translateY(-200px)' }),
          style({ opacity: 0.2, transform: 'translateY(-100px)' }),
          style({ opacity: 1, transform: 'translateY(0px)' })
        ]))
      ];
    }else if(type === 'fadeInBottom'){
      return [
        style({ opacity: 0, transform: 'translateY(200px)'}),
        animate(mode, keyframes([
          style({ opacity: 0, transform: 'translateY(200px)' }),
          style({ opacity: 0.2, transform: 'translateY(100px)' }),
          style({ opacity: 1, transform: 'translateY(0px)' })
        ])),
      ];
    }else if(type === 'fadeInLeft'){
      return [
        style({ opacity: 0, transform: 'translateX(-200px)'}),
        animate(mode, keyframes([
          style({ opacity: 0, transform: 'translateX(-200px)' }),
          style({ opacity: 0.2, transform: 'translateX(-100px)' }),
          style({ opacity: 1, transform: 'translateX(0px)' })
        ])),
      ];
    }else if(type === 'fadeInRight'){
      return [
        style({ opacity: 0, transform: 'translateX(200px)'}),
        animate(mode, keyframes([
          style({ opacity: 0, transform: 'translateX(200px)' }),
          style({ opacity: 0.2, transform: 'translateX(100px)' }),
          style({ opacity: 1, transform: 'translateX(0px)' })
        ])),
      ];
    }
  }

  private fadeOut(mode: string): AnimationMetadata[] {
    return [
      style({ opacity: '*' }),
      animate(mode, style({ opacity: 0 })),
    ];
  }

}

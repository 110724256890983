<div class="gallery-item-container" [ngStyle]="{backgroundColor:backGroundColor}">
  <div class="hover-show">
    <div class="hover-title">{{hoverTitle | translate}}</div>
    <div class="hover-detail" *ngFor="let text of hoverDetail">{{text | translate}}</div>
    <div class="hover-detail hover-detail-link" *ngIf="knowMoreText != null"><a href="{{knowMoreLink}}">{{knowMoreText | translate}}</a></div>
  </div>
  <img [src]="image" alt="" class="image-max">
  <div class="desc-container">
    <div class="desc-title">{{title | translate}}</div>
    <div class="desc-detail" *ngFor="let text of detail">{{text | translate}}</div>
  </div>
</div>
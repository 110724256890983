import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutModule } from '@angular/cdk/layout';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

// LIBRARY
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { CountoModule } from 'angular2-counto';
import { FlexLayoutModule } from '@angular/flex-layout';

// MATERIAL-UI
import { MaterialModule } from '../../material.module';

// COMPONENTS
import { HeaderComponent } from './components/header/header.component';
import { ExperienceComponent } from './components/experience/experience.component';
import { FooterComponent } from './components/footer/footer.component';
import { StrengthComponent } from './components/strength/strength.component';
import { StrengthContainerComponent } from './components/strength-container/strength.container.component';
import { NavigationBarComponent } from './components/navigation-bar/navigation-bar.component';
import { BottomFooterComponent } from './components/bottom-footer/bottom-footer.component';
import { SideBannerComponent } from './components/side-banner/side-banner.component';
import { SideNavbarComponent } from './components/side-navbar/side-navbar.component';
import { FullWideSliderComponent } from './components/full-wide-slider/full-wide-slider.component';
import { PartnerOverviewComponent } from './components/partner-overview/partner-overview.component';
import { FullWideHeaderTextComponent } from './components/full-wide-header-text/full-wide-header-text.component';
import { PartnerDetailSubComponent } from './components/partner-detail-sub/partner-detail-sub.component';
import { FullWideWithButtonComponent } from './components/full-wide-with-button/full-wide-with-button.component';
import { EventDetailComponent } from './components/event-detail/event-detail.component';
import { IvyCarouselModule } from './components/ivy-carousel-pro/carousel.module';
import { CustomInputComponent } from './components/custom-input/custom-input.component';
import { DateInputComponent } from './components/date-input/date-input.component';
import { AnimationOnScrollDirective } from './directives/animation-on-scroll.directive';
import { AboutVideoComponent } from './components/about-video/about-video.component';

const classesToInclude = [
  HeaderComponent,
  ExperienceComponent,
  FooterComponent,
  StrengthComponent,
  StrengthContainerComponent,
  NavigationBarComponent,
  BottomFooterComponent,
  SideBannerComponent,
  SideNavbarComponent,
  FullWideSliderComponent,
  PartnerOverviewComponent,
  FullWideHeaderTextComponent,
  PartnerDetailSubComponent,
  FullWideWithButtonComponent,
  EventDetailComponent,
  CustomInputComponent,
  DateInputComponent,
  AnimationOnScrollDirective,
  AboutVideoComponent
];

const modulesToImportExport = [
  MaterialModule,
  SlickCarouselModule,
  CountoModule,
  FlexLayoutModule,
  IvyCarouselModule,
  TranslateModule
];

@NgModule({
  declarations: classesToInclude,
  imports: [
    CommonModule,
    LayoutModule,
    modulesToImportExport,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [],
  exports: [modulesToImportExport, classesToInclude],
})
export class SharedModule {}

import {AfterViewInit, Component, OnInit,} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-partner-detail',
  templateUrl: './partner-detail.component.html',
  styleUrls: ['./partner-detail.component.scss'],
})
export class PartnerDetailComponent implements OnInit, AfterViewInit {


  constructor( private route: ActivatedRoute, private router: Router) {
  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    const compId = this.route.snapshot.params['id'];
    if (compId){
      console.log(compId);
    }
    const classs = document.getElementsByClassName('partner-detail-sub-container');
    if (classs && compId <= classs.length) {
        classs[compId - 1].scrollIntoView({block: 'center'});
    }
  }



}

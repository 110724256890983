import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { CommonService } from '../../services/common.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss'],
})
export class NavigationBarComponent implements OnInit {
  idName: any;
  isMobile = false;
  currentLang = 'tc';

  constructor(
    private commonService: CommonService,
    public breakpointObserver: BreakpointObserver,
    public translate: TranslateService
  ) {
    translate.addLangs(['tc', 'sc']);
    translate.setDefaultLang(this.currentLang);

    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/tc|sc/) ? browserLang : this.currentLang);
  }

  showSideNavbar() {
    this.commonService.changeSideNavbarIsShow(true);
  }
  closeSideNavbar() {
    this.commonService.changeSideNavbarIsShow(false);
  }
  ngOnInit(): void {
    this.breakpointObserver
      .observe(['(min-width: 992px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMobile = false;
        } else {
          this.isMobile = true;
        }
      });
  }
  switchLang(lang) {
    this.currentLang = lang;
    this.translate.use(this.currentLang);
  }
}

import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';

@Component({
  selector: 'app-full-wide-slider',
  templateUrl: './full-wide-slider.component.html',
  styleUrls: ['./full-wide-slider.component.scss'],
})
export class FullWideSliderComponent implements OnInit {

  @Input() title: string;
  @Input() detail: string;

  homeSlider1Config = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "autoplay" : true,
    "autoplaySpeed" : 3000
  };

  sliders = [
    {img: ""},
  ];

  constructor() {
  }

  ngOnInit(): void {
  }

}

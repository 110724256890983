import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-custom-input',
  templateUrl: './custom-input.component.html',
  styleUrls: ['./custom-input.component.scss'],
})
export class CustomInputComponent implements OnInit {
  @Input() fieldName: string;
  @Input() type: string;
  @Input() id: string;
  @Input() readonly = false;
  @Input() maxlength: number;
  @Input() control: FormControl;
  @Input() form: FormGroup;

  constructor() {}

  ngOnInit(): void {

  }

  hasRequredField(): boolean{
    if (this.control.validator){
      const validator = this.control.validator({} as AbstractControl);
      if (validator && validator.required){
        return true;
      }
    }
    return false;
  }
}

<app-full-wide-slider [title]="'partner.header_title'" [detail]="'partner.header_subtitle'"></app-full-wide-slider>
<div class="partner-overview-container">
  <div class="three-column-container">
    <div class="column"  appAnimationOnScroll [type]="'fadeInBottom'" [mode]="'600ms ease-out'">
      <app-partner-overview [image]="'assets/image/partner-page/kkwm.jpg'"
                            [title]="'partner.category_1_title'"
                            [detail]="['partner.category_1_detail']"
                            [hoverTitle]="'partner.category_1_hover_title'"
                            [hoverDetail]="['partner.category_1_hover_detail']"
                            [backGroundColor]="'#fcf9ed'"
                            [knowMoreText]="'partner.category_button_1'"
                            [knowMoreLink]="'http://www.kkwm.com.hk'"
      ></app-partner-overview>
    </div>
    <div class="column"  appAnimationOnScroll [type]="'fadeInBottom'" [mode]="'800ms ease-out'">
      <app-partner-overview [image]="'assets/image/partner-page/KP-logo_Horizontal.jpg'"
                            [title]="'partner.category_2_title'"
                            [detail]="['partner.category_2_detail']"
                            [hoverTitle]="'partner.category_2_hover_title'"
                            [hoverDetail]="['partner.category_2_hover_detail']"
                            [backGroundColor]="'#fcf9ed'"
                            [knowMoreText]="'partner.category_button_1'"
                            [knowMoreLink]="'https://www.easybyte-hk.com/kpc/#/pageTemplate?decorationId=1498203524054384640&storeId=1503622820159029248'"
      ></app-partner-overview>
    </div>
    <div class="column"  appAnimationOnScroll [type]="'fadeInBottom'" [mode]="'1000ms ease-out'">
      <app-partner-overview [image]="'assets/image/partner-page/food.jpg'"
                            [title]="'partner.category_3_title'"
                            [detail]="['partner.category_3_detail']"
                            [hoverTitle]="'partner.category_3_hover_title'"
                            [hoverDetail]="['partner.category_3_hover_detail_1',
                            'partner.category_3_hover_detail_2']"
                            [backGroundColor]="'#fcf9ed'"
                            [knowMoreText]="'partner.category_button_2'"
                            [knowMoreLink]="'https://www.easybyte-hk.com/kpc/#/pageTemplate?decorationId=1498180073214181376&storeId=1503622820159029248'"
      ></app-partner-overview>
    </div>
    <div class="column"  appAnimationOnScroll [type]="'fadeInBottom'" [mode]="'1200ms ease-out'">
      <app-partner-overview [image]="'assets/image/partner-page/accommodation.jpg'"
                            [title]="'partner.category_4_title'"
                            [detail]="['partner.category_4_detail']"
                            [hoverTitle]="'partner.category_4_hover_title'"
                            [hoverDetail]="['partner.category_4_hover_detail']"
                            [backGroundColor]="'#f2ecdc'"
                            [knowMoreText]="'partner.category_button_2'"
                            [knowMoreLink]="'https://www.easybyte-hk.com/kpc/#/pageTemplate?decorationId=1498226130191122432&storeId=1503622820159029248'"
      ></app-partner-overview>
    </div>
    <div class="column"  appAnimationOnScroll [type]="'fadeInBottom'" [mode]="'1400ms ease-out'">
      <app-partner-overview [image]="'assets/image/partner-page/education.jpg'"
                            [title]="'partner.category_5_title'"
                            [detail]="['partner.category_5_detail']"
                            [hoverTitle]="'partner.category_5_hover_title'"
                            [hoverDetail]="['partner.category_5_hover_detail']"
                            [backGroundColor]="'#fcf9ed'"
                            [knowMoreText]="'partner.category_button_1'"
                            [knowMoreLink]="'https://readingdreams.org.hk/'"
      ></app-partner-overview>
    </div>
    <div class="column"  appAnimationOnScroll [type]="'fadeInBottom'" [mode]="'1100ms ease-out'">
      <app-partner-overview [image]="'assets/image/partner-page/kkwecheck.jpg'"
                            [title]="'partner.category_6_title'"
                            [detail]="['partner.category_6_detail']"
                            [hoverTitle]="'partner.category_6_hover_title'"
                            [hoverDetail]="['partner.category_6_hover_detail']"
                            [backGroundColor]="'#fcf9ed'"
                            [knowMoreText]="'partner.category_button_1'"
                            [knowMoreLink]="'http://youngplus.com.hk/'"
      ></app-partner-overview>
    </div>
  </div>
</div>

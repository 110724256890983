import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {CommonService} from '../../services/common.service';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-side-navbar',
  templateUrl: './side-navbar.component.html',
  styleUrls: ['./side-navbar.component.scss'],
})
export class SideNavbarComponent implements OnInit, AfterViewInit {
  currentLang = 'tc';
  titles = [
    {
      name: 'nav.be_member',
      type: 'collapse',
      open: false,
      children: [{
        name: 'nav.membership_type',
        type: 'item',
        link: '/plan/grade'
      },
        {
          name: 'nav.membership_card',
          type: 'item',
          link: '/plan/outline'
        }]
    },
    {
      name: 'nav.membership',
      link: '/membership',
      type: 'collapse',
      open: false,
      children: [{
        name: 'nav.membership_point',
        type: 'item',
        link: '/membership/credit'
      },
        {
          name: 'nav.membership_offer',
          type: 'item',
          link: '/membership/event'
        }]
    },
    {
      name: 'nav.activity',
      link: '/activities',
      type: 'collapse',
      open: false,
      children: [{
        name: 'nav.join_activity',
        type: 'item',
        link: '/event/join'
      },
        {
          name: 'nav.activity_history',
          type: 'item',
          link: '/event/gallery'
        }]
    },
    {
      name: 'nav.partnership',
      link: '/partner',
      type: 'item'
    },
    {
      name: 'nav.news',
      link: '/latest',
      type: 'item'
    },
  ];

  @ViewChild('bump') bump: ElementRef;

  bump_position = '';

  bumpIsOpen = false;

  hoveredItem = -1;

  openBump() {
    this.bumpIsOpen = true;
  }

  closeBump() {
    this.bumpIsOpen = false;
  }

  isMobile = false;

  showSideNavbar = true;

  show = false;

  setHovered(position: number) {
    this.hoveredItem = position;
  }

  hideSideNavbar() {
    this.commonService.changeSideNavbarIsShow(false);

    //switch back to member login when hide
    setTimeout(() => {
      //back to init state
      this.showSideNavbar = true;
    }, 1000);
  }

  checkState(event, el) {
    event.preventDefault();
    el.checked = !el.checked;
  }

  openColumn(column) {
    column.open = !column.open;
  }

  constructor(
    private commonService: CommonService,
    public breakpointObserver: BreakpointObserver,
    public router: Router,
    public translate: TranslateService
  ) {
    translate.addLangs(['tc', 'sc']);
    translate.setDefaultLang(this.currentLang);

    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/tc|sc/) ? browserLang : this.currentLang);
  }

  ngOnInit(): void {
    this.commonService.sideNavbarIsShow.subscribe((show) => {
      // console.log('checkcheckcheckc 2')
      this.show = show;
    });

    this.breakpointObserver
      .observe(['(min-width: 991px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMobile = false;
        } else {
          this.isMobile = true;
        }
      });
  }

  checkChildrenLinks(target): any{
    for(const child of target.children){
      if(this.router.isActive(child.link, false)){
        return true;
      }
    }
    return false;
  }

  ngAfterViewInit(): void {
  }

  switchLang(lang) {
    this.currentLang = lang;
    this.translate.use(this.currentLang);
    this.hideSideNavbar();
  }
}

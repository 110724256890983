import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss'],
})
export class DateInputComponent implements OnInit {
  @Input() fieldName: string;
  @Input() type: string;
  @Input() id: string;
  @Input() readonly: boolean = false;
  @Input() control: FormControl;
  @Input() form: FormGroup;
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() filter: any;

  constructor() {}

  ngOnInit(): void {}

  hasRequredField(): boolean {
    if (this.control.validator) {
      const validator = this.control.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }
    return false;
  }
}

import { Component, OnInit } from '@angular/core';
import { Strength } from '../../../app.type';
import { StrengthsService } from '../../../strengths.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-strength-container',
  templateUrl: './strength.container.component.html',
  styleUrls: ['./strength.container.component.scss'],
  providers: [StrengthsService],
})
export class StrengthContainerComponent implements OnInit {

  public strengths: Strength[] = [];
  constructor(private strengthsService: StrengthsService) {}

  ngOnInit(): void {
    this.strengths = this.strengthsService.getStrengths();

  }
}

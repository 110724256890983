import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tnc',
  templateUrl: './tnc.component.html',
  styleUrls: ['./tnc.component.scss']
})
export class TncComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

<div class="policy-container">
    <h2>{{'privacy.privacy_title_1' | translate}}</h2>
    <h3>{{'privacy.privacy_subtitle_1' | translate}}</h3>
    <p>{{'privacy.privacy_title_1_content_1' | translate}}</p>
    <p>{{'privacy.privacy_title_1_content_2' | translate}}</p>
    <p>{{'privacy.privacy_title_1_content_3' | translate}}</p>
    <p>{{'privacy.privacy_title_1_content_4' | translate}}</p>
    <p>{{'privacy.privacy_title_1_content_5' | translate}}</p>
    <p>{{'privacy.privacy_title_1_content_6' | translate}}</p>
    <p>{{'privacy.privacy_title_1_content_7' | translate}}</p>
    <p>{{'privacy.privacy_title_1_content_8' | translate}}</p>
    <p>{{'privacy.privacy_title_1_content_9' | translate}}</p>
    <h3>{{'privacy.privacy_subtitle_2' | translate}}</h3>
    <p>{{'privacy.privacy_title_1_content_10' | translate}}</p>
    <p>{{'privacy.privacy_title_1_content_11' | translate}}</p>
    <p>{{'privacy.privacy_title_1_content_12' | translate}}</p>
    <h3>{{'privacy.privacy_subtitle_3' | translate}}</h3>
    <p>{{'privacy.privacy_title_1_content_13' | translate}}</p>
    <h3>{{'privacy.privacy_subtitle_4' | translate}}</h3>
    <p>{{'privacy.privacy_title_1_content_14' | translate}}</p>
    <p>{{'privacy.privacy_title_1_content_14_a' | translate}}</p>
    <p>{{'privacy.privacy_title_1_content_14_b' | translate}}</p>
    <p>{{'privacy.privacy_title_1_content_14_c' | translate}}</p>
    <p>{{'privacy.privacy_title_1_content_14_d' | translate}}</p>
    <p>{{'privacy.privacy_title_1_content_14_e' | translate}}</p>
    <h3>{{'privacy.privacy_subtitle_5' | translate}}</h3>
    <p>{{'privacy.privacy_title_1_content_15' | translate}}</p>
    <p>{{'privacy.privacy_title_1_content_15_1' | translate}}</p>
    <p>{{'privacy.privacy_title_1_content_15_a' | translate}}</p>
    <p>{{'privacy.privacy_title_1_content_15_b' | translate}}</p>
    <p>{{'privacy.privacy_title_1_content_15_b_i' | translate}}</p>
    <p>{{'privacy.privacy_title_1_content_15_b_ii' | translate}}</p>
    <p>{{'privacy.privacy_title_1_content_15_b_iii' | translate}}</p>
    <p>{{'privacy.privacy_title_1_content_15_b_iv' | translate}}</p>
    <p>{{'privacy.privacy_title_1_content_15_c' | translate}}</p>
    <p>{{'privacy.privacy_title_1_content_15_c_i' | translate}}</p>
    <p>{{'privacy.privacy_title_1_content_15_c_ii' | translate}}</p>
    <p>{{'privacy.privacy_title_1_content_15_c_iii' | translate}}</p>
    <p>{{'privacy.privacy_title_1_content_15_c_iv' | translate}}</p>
    <p>{{'privacy.privacy_title_1_content_15_c_v' | translate}}</p>
    <p>{{'privacy.privacy_title_1_content_16' | translate}}</p>
    <p>{{'privacy.privacy_title_1_content_17' | translate}}</p>
    <h3>{{'privacy.privacy_subtitle_6' | translate}}</h3>
    <p>{{'privacy.privacy_title_1_content_18' | translate}}</p>
    <p>{{'privacy.privacy_title_1_content_18_1' | translate}}</p>
    <p>{{'privacy.privacy_title_1_content_19' | translate}}</p>
    <p>{{'privacy.privacy_title_1_content_20' | translate}}</p>
    <h3>{{'privacy.privacy_subtitle_7' | translate}}</h3>
    <p>{{'privacy.privacy_title_1_content_21' | translate}}</p>
    <p>{{'privacy.privacy_title_1_content_21_a' | translate}}</p>
    <p>{{'privacy.privacy_title_1_content_21_b' | translate}}</p>
    <p>{{'privacy.privacy_title_1_content_21_c' | translate}}</p>
    <h3>{{'privacy.privacy_subtitle_8' | translate}}</h3>
    <p>{{'privacy.privacy_title_1_content_22' | translate}}</p>
    <h3>{{'privacy.privacy_subtitle_9' | translate}}</h3>
    <p>{{'privacy.privacy_title_1_content_23' | translate}}</p>
    <p>{{'privacy.privacy_title_1_content_23_1' | translate}}</p>
    <p>{{'privacy.privacy_title_1_content_23_2' | translate}}</p>
    <p>{{'privacy.privacy_title_1_content_23_3' | translate}}</p>
    <p>{{'privacy.privacy_title_1_content_23_4' | translate}}</p>
    <p>{{'privacy.privacy_title_1_content_23_5' | translate}}</p>
    <p>{{'privacy.privacy_title_1_content_23_6' | translate}}</p>
    <h3>{{'privacy.privacy_subtitle_10' | translate}}</h3>
    <p>{{'privacy.privacy_title_1_content_24' | translate}}</p>
    <h3>{{'privacy.privacy_subtitle_11' | translate}}</h3>
    <p>{{'privacy.privacy_title_1_content_25' | translate}}</p>
    <p>{{'privacy.privacy_title_1_content_25_1' | translate}}</p>
</div>
<div class="carousel-counter" *ngIf="isCounter">{{counter}}</div>

<div class="carousel-container" [class.carousel-moving]="isMoving">
	<div class="carousel-cells" #cells (transitionend)="handleTransitionendCellContainer($event)">
		<ng-content></ng-content>

		<ng-template ngFor let-image [ngForOf]="images" let-i="index">
			<div class="carousel-cell"
				*ngIf="i < cellLimit"
           [style.width]="carousel.cellWidth+'px'"
           [style.border-radius]="borderRadius+'px'">

        <!-- Image -->
        <a (click)="click(i)">
          <img
					*ngIf="getFile(i) && getFile(i)['image']"
					[src]="getFile(i)['image']['path']"
					[style.object-fit]="objectFit"
          draggable="false"
          />
        </a>


				<!-- Video -->
				<ng-template [ngIf]="getFile(i) && getFile(i)['type'] === 'video'">
					<div class="carousel-play" (click)="toggleVideo(video)"></div>

					<video #video
						[loop]="videoProperties.loop"
						[muted]="videoProperties.muted"
						[controls]="isSafari"
						[style.object-fit]="objectFit"
						[style.border-radius]="borderRadius+'px'">

						<source [src]="getFile(i)['path']" type="video/mp4">
					</video>
				</ng-template>
			</div>
		</ng-template>
	</div>

	<div class="carousel-dots" *ngIf="dots">
		<div class="carousel-dot" [class.carousel-dot-active]="i === activeDotIndex" *ngFor="let dot of dotsArr; index as i"></div>
	</div>
</div>

<div class="carousel-arrows"
	[class.carousel-arrows-outside]="arrowsOutside"
	[class.carousel-dark-arrows]="arrowsTheme === 'dark'"
	*ngIf="arrows">

	<div class="carousel-arrow carousel-arrow-prev" [class.carousel-arrow-disabled]="isPrevArrowDisabled()" (click)="prev()"></div>
	<div class="carousel-arrow carousel-arrow-next" [class.carousel-arrow-disabled]="isNextArrowDisabled()" (click)="next()"></div>
</div>

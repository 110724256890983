
import {AfterViewInit, Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.scss'],
})
export class EventDetailComponent implements OnInit, AfterViewInit {

  @Input() productImages: any[];
  @Input() title: string;
  @Input() detail: string[];
  @Input() eventDate: string;
  currentImageUrl;

  constructor() {}

  ngOnInit(): void {
    this.currentImageUrl = this.productImages[0].path;
  }
  ngAfterViewInit(): void{
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 500);
}


  handleCarouselEvents(e) {
    if (e.currentSliderIndex !== undefined) {
      this.currentImageUrl = this.productImages[e.currentSliderIndex].path;
      console.log(this.currentImageUrl)
    }
  }
}

<div class="bottom-footer-main-container">

  <!-- main container -->
  <div class="main-container" fxLayout="column" fxLayoutAlign="start start">
    <!-- desktop mode -->
    <ng-container *ngIf="!isMobile">
      <!-- top part -->
      <div class="top-container" fxLayout="row" fxLayoutAlign="space-between start">
        <!-- leftmost-container -->
        <div class="leftmost-container" fxLayout="column" fxLayoutAlign="start start" fxFlex="40"
          style="margin-right: 5%;">
          <!-- title container -->
          <div class="title-container">{{'footer.contact_title_1' | translate}}</div>

          <!-- details container -->
          <div class="details-container">
            <div class="details">{{'footer.contact_title_1_content_1' | translate}}</div>
            <div class="details">{{'footer.contact_title_1_content_1_1' | translate}}</div>
            <div class="details">{{'footer.contact_title_1_content_2' | translate}}</div>
            <div class="details">{{'footer.contact_title_1_content_2_1' | translate}}</div>
            <!-- <div class="details">
              {{'footer.contact_title_1_content_2' | translate}}
            </div> -->

            <div class="details">{{'footer.contact_title_1_content_3' | translate}}</div>

            <div fxLayout="row" fxLayoutAlign="start start">
              <div class="details" style="width: 60px;">{{'footer.contact_title_1_content_4' | translate}}</div><a
                class="details" href="mailto: info@kingpclub.com">info@kingpclub.com</a>

            </div>
          </div>
        </div>

        <!-- middle -->
        <div class="middle" fxLayout="column" fxLayoutAlign="start start" fxFlex="20">
          <!-- title part -->
          <div class="title-container">{{'footer.socialmedia_title_1' | translate }}</div>
          <!-- logo part -->
          <div class="logo-container" fxLayout="row" fxLayoutAlign="start start">
            <a href="https://www.instagram.com/king_prestige_club/" target="_blank">
              <img src="../../../../assets/image/bottom-footer/icon-instagram-white.svg" />
            </a>
            <a class="wechat-link" href="https://www.easybyte-hk.com/kpc/#/index" target="_blank">
              <img src="../../../../assets/image/bottom-footer/-e-wechat.png" />
            </a>
            <a href="https://www.facebook.com/King-Prestige-Club-%E4%BA%AC%E7%B2%B5%E6%9C%83-2055303574575075/"
              target="_blank">
              <img src="../../../../assets/image/bottom-footer/icon-facebook-white.svg" />
            </a>

          </div>
        </div>
      </div>
    </ng-container>

    <!-- mobile mode -->
    <ng-container *ngIf="isMobile">
      <!-- top part -->
      <div class="top-container" fxLayout="column" fxLayoutAlign="start start">
        <!-- leftmost-container -->
        <div class="leftmost-container" fxLayout="column" fxLayoutAlign="start start">
          <!-- title container -->
          <div class="title-container">{{'footer.contact_title_1' | translate}}</div>

          <!-- details container -->
          <div class="details-container">
            <div class="details">{{'footer.contact_title_1_content_1' | translate}}</div>
            <div class="details">{{'footer.contact_title_1_content_1_1' | translate}}</div>
            <div class="details">{{'footer.contact_title_1_content_2' | translate}}</div>
            <div class="details">{{'footer.contact_title_1_content_2_1' | translate}}</div>

            <!-- <div class="details">
              {{'footer.contact_title_1_content_2' | translate}}
            </div> -->

            <div class="details">{{'footer.contact_title_1_content_3' | translate}}</div>

            <div fxLayout="row" fxLayoutAlign="start start">
              <div class="details" style="width: 60px;">{{'footer.contact_title_1_content_4' | translate}}</div><a
                class="details" href="mailto: info@kingpclub.com">info@kingpclub.com</a>

            </div>

          </div>
        </div>

        <!-- middle -->
        <div class="middle" fxLayout="column" fxLayoutAlign="start start">
          <!-- title part -->
          <div class="title-container">{{'footer.socialmedia_title_1' | translate }}</div>

          <!-- logo part -->
          <div class="logo-container" fxLayout="row" fxLayoutAlign="start start">
            <a href="https://www.instagram.com/king_prestige_club/" target="_blank">
              <img src="../../../../assets/image/bottom-footer/icon-instagram-white.svg" />
            </a>
            <a href="http://t.weimob.com/Wg9mB" target="_blank">
              <img src="../../../../assets/image/bottom-footer/-e-wechat.png" />
            </a>
            <a href="https://www.facebook.com/King-Prestige-Club-%E4%BA%AC%E7%B2%B5%E6%9C%83-2055303574575075/"
              target="_blank">
              <img src="../../../../assets/image/bottom-footer/icon-facebook-white.svg" />
            </a>

          </div>
        </div>

        <!-- rightmost-container -->
        <div class="rightmost-container" fxLayout="column" fxLayoutAlign="start start">
          <!--          <div class="title-container">快速鏈接</div>-->

          <div class="button-container" fxLayout="row" fxLayoutAlign="space-between start">
            <!--            <button class="button" routerLink="/plan">成為會員</button>-->
            <!--            <button class="button" routerLink="/partner">會員服務</button>-->
            <!--            <button class="button" routerLink="/activities-page">活動</button>-->
          </div>
        </div>
      </div>
    </ng-container>

    <!-- weird grey line -->
    <div class="grey-line"></div>
    <ng-container *ngIf="!isMobile">
      <!-- bottom part -->
      <div class="bottom-container" fxLayout="row" fxLayoutAlign="space-between start">
        <div class="bottom-leftmost" fxLayout="row" fxLayoutAlign="space-between start" fxFlex="30">
          <a class="bottom-link" [routerLink]="'/privacy-policy'">{{'footer.privacy_title_1' | translate}}</a>
          <a class="bottom-link" [routerLink]="'/tnc'">{{'footer.privacy_title_2' | translate}} </a>
          <a class="bottom-link" [routerLink]="'/data-policy'">{{'footer.privacy_title_3' | translate}}</a>
        </div>
        <div class="bottom-rightmost" fxFlex="50">
          {{'footer.copyright_title_1' | translate}}
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="isMobile">
      <!-- bottom part -->
      <div class="bottom-container" fxLayout="column" fxLayoutAlign="space-between start">
        <div class="bottom-leftmost" fxLayout="row" fxLayoutAlign="space-between start">
          <a class="bottom-link"
            (click)="goToLink('https://ddp-public.s3-ap-southeast-1.amazonaws.com/ukf/privacy.pdf')">{{'footer.privacy_title_1'
            | translate}}</a>
          <a class="bottom-link"
            (click)="goToLink('https://ddp-public.s3-ap-southeast-1.amazonaws.com/ukf/policy.pdf')">{{'footer.privacy_title_2'
            | translate}} </a>
          <a class="bottom-link"
            (click)="goToLink('https://ddp-public.s3-ap-southeast-1.amazonaws.com/ukf/sending.pdf')">{{'footer.privacy_title_3'
            | translate}}</a>
        </div>
        <div class="bottom-rightmost" style="text-align: center;">
          {{'footer.copyright_title_1' | translate}}
        </div>
      </div>

    </ng-container>
  </div>
</div>
<div class="has-float-label" [formGroup]="form">
  <input
    [ngClass]="{'error': control.invalid && (control.dirty || control.touched) && control.errors,
    touched: control.dirty || control.touched}"
    [formControl]="control"
    [placeholder]="fieldName"
    [matDatepicker]="picker"
    [min]="minDate"
    [max]="maxDate"
    [matDatepickerFilter]="filter"
    matInput
  />
  <label [for]="id"
    ><span class="required-indicator" *ngIf="hasRequredField()"> * </span
    >{{ fieldName }}</label
  >
  <mat-datepicker-toggle class="date-picker-button" matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
  <div
    *ngIf="control.invalid && (control.dirty || control.touched)"
    style="display: flex; justify-content: flex-end"
  >
    <span *ngIf="control.errors.required" class="error"
      >*必填</span
    >
  </div>
</div>

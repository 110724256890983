
import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-partner-detail-sub',
  templateUrl: './partner-detail-sub.component.html',
  styleUrls: ['./partner-detail-sub.component.scss'],
})
export class PartnerDetailSubComponent implements OnInit {

  @Input() image: string;
  @Input() title: string;
  @Input() detail: string[];
  @Input() left: boolean;


  constructor() {}

  ngOnInit(): void {
  }

  getImage(){
    return 'url("' + this.image + ')';
  }
}

<div class="policy-container">
    <h2>{{'terms.tnc_title_1' | translate}}</h2>
    <p>{{'terms.tnc_title_1_content_1' | translate}}</p>
    <h3>{{'terms.tnc_title_2' | translate}}</h3>
    <p>{{'terms.tnc_title_2_content_1' | translate}}</p>
    <h3>{{'terms.tnc_title_3' | translate}}</h3>
    <p>{{'terms.tnc_title_3_content_1' | translate}}</p>
    <h3>{{'terms.tnc_title_4' | translate}}</h3>
    <p>{{'terms.tnc_title_4_content_1' | translate}}</p>
    <h3>{{'terms.tnc_title_5' | translate}}</h3>
    <p>{{'terms.tnc_title_5_content_1' | translate}}</p>
    <p>{{'terms.tnc_title_5_content_2' | translate}}</p>
    <h3>{{'terms.tnc_title_6' | translate}}</h3>
    <p>{{'terms.tnc_title_6_content_1' | translate}}
    <h3>{{'terms.tnc_title_7' | translate}}</h3>
    <p>{{'terms.tnc_title_7_content_1' | translate}}</p>
    <h3>{{'terms.tnc_title_8' | translate}}</h3>
    <p>{{'terms.tnc_title_8_content_1' | translate}}</p>
    </div>
<div class="full-width-with-button home-banner">
  <ngx-slick-carousel class="carousel"
                      #slickModal="slick-carousel"
                      [config]="homeSlider1Config">
    <div ngxSlickItem *ngFor="let slide of sliders" class="slide" style="background: black">
      <img src="/assets/image/event-page/event-banner.png" alt="" style="opacity:0.5;width: 100%; height: auto;" >

    </div>
  </ngx-slick-carousel>
  <div class="slider-text-container">
    <div class="slider-title">{{title | translate}}</div>
    <div class="slider-detail">{{detail | translate}}</div>
    <button class="button">
      <a href="https://www.easybyte-hk.com/kpc/#/registerMember" target="_blank">{{'event.activities_button' | translate}}</a>
    </button>
  </div>
</div>

<div class="partner-detail-sub-container" [ngStyle.gt-sm]="{justifyContent:left?'flex-start':'flex-end',backgroundImage:getImage(),backgroundPosition:left?'right center':'left center'}">
  <div class="partner-detail-sub-left" >
    <div class="golden-flag">
      <div class="img-parent"><img src="assets/image/shared/gold-flag.png" class="top-left-img" alt=""></div>
      <div class="flag-in-text">
        <div class="main">{{title | translate}}</div>
      </div>
    </div>
    <div class="text-container">
      <div class="detail" *ngFor="let text of detail">{{text | translate}}</div>
    </div>
  </div>
</div>

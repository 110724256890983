<section id="experience">
  <ng-container *ngIf="!isMobile">
    <div class="main-container" fxLayout="row" fxLayoutAlign="start center">
      <div class="image-container" fxFlex="60" appAnimationOnScroll [type]="'fadeInLeft'" [mode]="'700ms ease-out'">
        <img src="../../../../assets/image/home-page/layer-sea.jpg">
      </div>
      <div class="right-container" fxFlex="40" appAnimationOnScroll [type]="'fadeInRight'" [mode]="'700ms ease-out'">
        <div class="content-container">
          <h1 class="title-container darkGold">{{'home.experience_title_1' | translate}}</h1>
          <p class="description">
            {{'home.experience_title_1_content_1' | translate}}<br/>
            {{'home.experience_title_1_content_2' | translate}}
          </p>
          <button>
            <a href="https://www.easybyte-hk.com/kpc/#/index" target="_blank">{{'home.experience_title_1_button' | translate}}</a> 
          </button>
        </div>
        <app-about-video></app-about-video>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="isMobile">
    <div class="main-container" fxLayout="column" fxLayoutAlign="start center">

      <div class="image-container" appAnimationOnScroll [type]="'fadeInBottom'" [mode]="'700ms ease-out'">
        <img src="../../../../assets/image/home-page/layer-sea.jpg">
      </div>
      <div class="content-container" appAnimationOnScroll [type]="'fadeInBottom'" [mode]="'700ms ease-out'">
        <h1 class="title-container darkGold">{{'home.experience_title_1' | translate}}</h1>
        <p class="description">
          {{'home.experience_title_1_content_1' | translate}}
        </p>
          <button>{{'home.experience_title_1_button' | translate}}</button>
      </div>
      <app-about-video></app-about-video>
    </div>
  </ng-container>
</section>
<app-full-wide-header-text [title]="'合作夥伴'" [detail]="'我們不時籌辦多項活動，請即關註我們，讓我們向您發送最新消息'"></app-full-wide-header-text>
<div class="partner-detail-container">
  <div class="row">
    <app-partner-detail-sub [image]="'assets/image/partner-detail/layer1.png'"
                            [title]="'京基優越財富管理'"
                            [left]="true"
                            [detail]="['京基優越財富管理有限公司秉承“理財創富．信守傳承”公司理念，敢於創新挑戰，透過提供廣泛的產品及服務，利用多元化的財富管理模式，為廣大尊貴客戶服務，致力為客戶實現全方位理財，資產增值及財富傳承之目標。',
'透過拓展產品種類，京基優越財富管理有限公司及聯營公司在大中華地區提供風險保障，財務策劃，保險服務，企業融資，資產管理，證券交易，基金投資，強制性公積金，借貸服務，信托服務，本地及海外物業投資，投資移民咨詢及按揭轉介等多元化服務，積極打造壹站式財富管理平臺。',
'以香港作為基地，中國作為基礎，京基優越財富管理有限公司以提供專業，誠信及全面的理財策劃服務予大中華區以及亞洲區內的機構及個人客戶為目標，竭誠為客戶提供專業的理財策劃咨詢及財富管理服務。'
]"></app-partner-detail-sub>
  </div>
  <div class="row">
    <app-partner-detail-sub [image]="'assets/image/partner-detail/layer2.png'"
                            [title]="'粵創商業研究所'"
                            [left]="false"
                            [detail]="['粵創商業研究所的出現，是真正把握全球經濟快速增長的壹個重大商機。',
'研究所壹直以「創新商業．家族傳承」為理念，積極參與並推動社會及人力培訓的資源，以適應知識型社會的繁盛發展，藉此成為亞洲具有質素、規模的專業培訓及項目統籌機構。',
'粵創商業研究所招納了數名壹流的企業咨詢顧問、投資管理及財資策劃專家作為核心團隊，為企業提供壹站式企業診癥及後續服務，讓各家族成員及企業客戶更加了解各類金融市場，並進行分析和提供企業戰略，使家族成員或家族企業繼承人輕易而舉地掌握基本投資技巧及理念，從而成為大中華以至亞洲地區的商界領袖。期望藉此為亞洲及海外企業提供新視野、新策略，並打造全新的亞太區商界平臺。',
'粵創推出的課程具備優質的教學資源，在不久的未來將培育出壹批具備投資觸覺，能夠掌握世界尖端商業知識的金融專家和投資者及可將管理和實踐融會貫通的優秀企業人才。'
]"></app-partner-detail-sub>
  </div>
  <div class="row">
    <app-partner-detail-sub [image]="'assets/image/partner-detail/layer3.png'"
                            [title]="'京基“家辦”服務'"
                            [left]="true"
                            [detail]="['家族理財管家 為您世代領航',
                            '京基“家辦”服務為香港壹家具規模的家族辦公室，是香港上市公司京基金融國際(1468.HK)壹手創立，總部位於全球三大國際金融中心–香港。',
'京基的族辦由京基金融國際司旗下持有全面的香港金融牌照的子公司提供服務，包括:證券、資產管理、保險、基金、信托及地產。我們致力於打造成為大灣區高端金融標杆及「家辦」服務先駒企業。',
'京基“家辦”服務為家族企業提供全方位財富管理、資產管理及家族事務服務，以使其資產得以長期發展，符合家族的預期和期望，並能夠順利地進行跨代傳承、保值和增值。'
]"></app-partner-detail-sub>
  </div>
  <div class="row">
    <app-partner-detail-sub [image]="'assets/image/partner-detail/layer4.png'"
                            [title]="'亞洲醫旅通會員卡'"
                            [left]="false"
                            [detail]="['醫旅通會員卡是壹張多用途的優惠卡，憑卡於亞洲醫旅平臺預約香港及海外醫療機構的醫療服務，即可盡享全年折扣優惠，更可享受旅遊，購物及餐飲優惠。只須壹人申請，您的家人同樣享有豐厚禮遇。',
'壹卡在手，盡享健康優悠。會員尊享福利：•24小時網上預約平臺•免費通訊服務•中港車接送優惠•海外酒店折扣優惠•機票折扣•景點門票優惠•餐飲，購物，娛樂優惠'

]"></app-partner-detail-sub>
  </div>
  <div class="row">
    <app-partner-detail-sub [image]="'assets/image/partner-detail/layer5.png'"
                            [title]="'京粵健康站疫苗服務'"
                            [left]="true"
                            [detail]="['京粵健康站為您提供預約9合1，嬰幼兒及其他種類的疫苗。','只需十天前鎖定預約日期及時間。','檢查後，醫生即可替嬰幼兒註射疫苗。'

]"></app-partner-detail-sub>
  </div>
  <div class="row">
    <app-partner-detail-sub [image]="'assets/image/partner-detail/layer6.png'"
                            [title]="'動態心電圖檢查'"
                            [left]="false"
                            [detail]="['動態心電圖(Holter Monitor)是壹種便攜式監測儀，首先用六個小型電極或導線黏貼到您的胸部，並通過壹系列的細電線連接到記錄儀。',
                            '心臟活動的電子信號通過電極傳導到監測儀記錄。',
                            '待您返還監測儀器時，記錄的數據會被計算機回放、掃描和分析，然後打印報告，經由醫生來復審分析，壹般壹星期內就可以有監測結果的報告，醫生並將為您作出準備的診斷，並進壹步制定適合您的最佳治療方案。']">

    </app-partner-detail-sub>
  </div>
  <div class="row">
    <app-partner-detail-sub [image]="'assets/image/partner-detail/layer7.png'"
                            [title]="'心臟心磁檢測'"
                            [left]="true"
                            [detail]="['世界最頂尖心臟檢驗科技 - 心磁檢測',
                            '全球只有4臺，亞洲只有1臺對心臟疾病有更精準檢測作用',
                            '與其他檢查例如冠狀動脈造影(CTA)具有相同的準確度(> 90％)',
                            '配合7項先進心臟手術 ，減低中風機會達90％']">

    </app-partner-detail-sub>
  </div>
  <div class="row">
    <app-partner-detail-sub [image]="'assets/image/partner-detail/layer8.png'"
                            [title]="'DNA基因檢測'"
                            [left]="false"
                            [detail]="['DNA基因檢測是進行基因的測試來找到更多關於壹個人的基因構成。壹些常見的基因測試包括可能導致疾病的基因突變測試，或者進行檢測以確定先祖關系。',
                            '成長導向基因檢測',
                            '全方位了解孩子與生俱來的天賦及潛力，助您規劃適合的發展領域，讓他們健康快樂地成長。',
                            '全基因譜掃描',
                            '透過全基因譜掃描檢測中得到個人 2 3 對染色體中數以百萬計的基因多型性結果，從而知道基因是如何影響自己，甚至追溯家族疾病的感受性，有助設計更個體化、更有效的醫療與保健方案。',
                            '運動基因檢測',
                            '透過 WeCheck Sport 運動基因檢測，助妳尋找壹套適合自己或孩子的健康飲食及運動方式。從而選擇合適的運動，達至健康的標準。']">

    </app-partner-detail-sub>
  </div>
  <div class="row">
    <app-partner-detail-sub [image]="'assets/image/partner-detail/layer9.png'"
                            [title]="'壹對壹品味形象塑造'"
                            [left]="true"
                            [detail]="['女士',
                            '將為女性會員安排壹系列女性品味形象顧問服務，從個人護理、健康美容，服裝搭配、珠寶首飾，以及參加世界最新時裝秀、獲邀出席獨特的品牌活動。',
                            '男士','將為男士安排品味形象顧問服務，包括度身訂造西裝，配合會員個人個性及形象，設計出獨壹無二及適合出席各種場合的弁禮服。'
                            ]">

    </app-partner-detail-sub>
  </div>
</div>

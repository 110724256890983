<div class="has-float-label" [formGroup]="form">
  <input
    [ngClass]="{'error':control.invalid && (control.dirty || control.touched) && control.errors,
    'touched': (control.dirty || control.touched)}"
    [formControl]="control"
    [placeholder]="fieldName"
    [type]="type"
    [id]="id"
    [readonly]="readonly"
    [maxlength]="maxlength"
    [name]="fieldName"
  >
  <label [for]="id" ><span class="required-indicator" *ngIf="hasRequredField()"> * </span>{{fieldName | translate}}</label>
  <div *ngIf="control.invalid && (control.dirty || control.touched)" style="display: flex;justify-content: flex-end;">
    <span *ngIf="control.errors.required" class="error" >{{'error_message.required' | translate}}</span>
    <span *ngIf="control.errors.email" class="error" >{{'error_message.email_format' | translate}}</span>
    <span *ngIf="control.errors.pattern && type=='email'"  class="error" >{{'error_message.email_format' | translate}}</span>
    <span *ngIf="control.errors.pattern && type=='tel'" class="error" >{{'error_message.phone_format_1' | translate}}</span>
    <span *ngIf="control.errors.pattern && type=='text' && control.errors.pattern.requiredPattern == '^[0-9a-zA-Z]{6,9}$'" class="error" >{{'error_message.phone_format_2' | translate}}</span>
    <span *ngIf="control.hasError('notMatchPassword')" class="error">{{'error_message.password' | translate}}</span>
    <span *ngIf="control.hasError('passwordFormatError')" class="error multi-line">
      <div *ngIf="control.hasError('caseError')">{{'error_message.password_format_1' | translate}}</div>
      <div *ngIf="control.hasError('numberSymbolError')">{{'error_message.password_format_2' | translate}}</div>
      <div *ngIf="control.hasError('lengthError')">{{'error_message.password_format_3' | translate}}</div>
    </span>
  </div>
</div>

<header>
  <video #video autoplay loop [muted]="true">
    <source src="/assets/image/home-page/embed-video.mp4" type="video/mp4"/>
    Your browser does not support the video tag.
  </video>
  <div id="header-content">
      <div class="golden-flag">
        <div class="img-parent"><img src="assets/image/shared/gold-flag.png" class="top-left-img" alt=""></div>
        <div class="flag-in-text">
          <div class="main">{{'home.banner_box_title' | translate}}</div>
          {{'home.banner_box_subtitle' | translate}}
        </div>
      </div>
    <p id="header-content-paragraph" class="lightGold">
      {{'home.banner_box_desc' | translate}}
    </p>
  </div>
</header>

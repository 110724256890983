<div class="full-width-slider home-banner">
  <ngx-slick-carousel class="carousel"
                      #slickModal="slick-carousel"
                      [config]="homeSlider1Config">
    <div ngxSlickItem *ngFor="let slide of sliders" class="slide" style="background: black">
      <img src="/assets/image/partner-page/partnership-banner.jpg" alt="" style="opacity:0.5;width: 100%; height: auto;object-fit: cover" >

    </div>
  </ngx-slick-carousel>
  <div class="slider-text-container">
    <div class="slider-title">{{title | translate}}</div>
    <div class="slider-detail">{{detail | translate}}</div>
  </div>
</div>

<ng-container *ngIf="isMobile">
  <div [style]="show ? 'transform: translateX(0%)' : 'transform: translateX(150%)'" class="side-navbar-container">
    <div *ngIf="showSideNavbar" class="content-bar">
      <a class="cross" (click)="hideSideNavbar()">
        <img src="../../../../assets/image/side-navbar/cross_button.png" />
      </a>
      <div class="yellow-bar"></div>

      <div class="top-container">
        <div class="title-container">
          <a routerLink="/home" (click)="hideSideNavbar()">
            <img class="scm_logo" src="../../../../assets/image/navigation-bar/KP-logo_Horizontal.png" alt="logo" />
          </a>
        </div>
        <div fxLayout="column" fxLayoutAlign="start start" class="menu-list w100">
          <ng-container *ngFor="let title of titles, index as i">
            <div class="menu-big-item" *ngIf="title.type == 'item'">
              <ng-container>
                <a [ngStyle]="{borderBottom: i==titles.length-1?'none':'1px solid white'}" class="menu-item" (click)="hideSideNavbar()" routerLink="{{ title.link }}" routerLinkActive="active">
                  {{ title.name | translate}}
                </a>
              </ng-container>
            </div>
            <div class="menu-big-item" *ngIf="title.type == 'collapse'">
              <ng-container>
                <a [ngStyle]="{borderBottom: i==titles.length-1?'none':'1px solid white'}" class="menu-item" style="border-bottom:none" matRipple (click)="openColumn(title)" [class.active]="checkChildrenLinks(title)">
                  {{ title.name | translate}}
                  <mat-icon style="color:white">keyboard_arrow_down</mat-icon>
                </a>

              </ng-container>

              <ng-container *ngFor="let child of title.children">
                <a *ngIf="title.open" class="menu-item-child" (click)="hideSideNavbar()" routerLink="{{ child.link }}" routerLinkActive="active">
                  {{ child.name | translate}}
                </a>

              </ng-container>
              <hr style="border-bottom:1px solid white;width: 80%" />
            </div>
          </ng-container>

          <div class="language-container">
            <img class="registration-logo" src="../../../../assets/image/navigation-bar/-e-account.png" (click)="hideSideNavbar()" routerLink="/member" />
            <div class="gold-register">
              <div class="lang-selector" (click)="switchLang('tc')" [ngStyle]="{'color': currentLang=='tc' ? '#f4e48c' : '#a38a45' }">繁</div>
              <div class="separator">|</div>
              <div class="lang-selector" (click)="switchLang('sc')" [ngStyle]="{'color': currentLang=='sc' ? '#f4e48c' : '#a38a45' }">简</div>
              <div class="separator">|</div>
              <div class="lang-selector" (click)="switchLang('en')" [ngStyle]="{'color': currentLang=='en' ? '#f4e48c' : '#a38a45' }">EN</div>
            </div>
          </div>
          
          <div class="social-media" fxLayout="row" fxLayoutAlign="space-between center">
            <a href="https://www.instagram.com/king_prestige_club/" target="_blank"><img src="../../../../assets/image/bottom-footer/icon-instagram-white.svg" /></a>
            <a href="https://www.facebook.com/King-Prestige-Club-%E4%BA%AC%E7%B2%B5%E6%9C%83-2055303574575075/" target="_blank"><img src="../../../../assets/image/bottom-footer/-e-wechat.png" /></a>
            <a href="https://www.facebook.com/King-Prestige-Club-%E4%BA%AC%E7%B2%B5%E6%9C%83-2055303574575075/" target="_blank"><img src="../../../../assets/image/bottom-footer/icon-facebook-white.svg" alt="" /></a>
          </div>
        </div>
        <!--        todo href-->
      </div>
    </div>
  </div>
</ng-container>
<!-- fix this later in desktop mode it disappear but other mode it reappear -->
<ng-container *ngIf="!isMobile">
  <!-- hideSideNavbar() -->
</ng-container>
<div class="navigation-bar-container" fxLayout="row" fxLayoutAlign="center stretch">
  <ng-container>
    <div class="main-container" fxLayout="row" fxLayoutAlign="space-between center" fxFlex="90">
      <!-- left part of the header -->
      <a class="logo-left" fxFlex="10" routerLink="/home">
        <img class="company-logo" src="../../../../assets/image/navigation-bar/KP-logo_Horizontal.png" />
      </a>

      <!-- rightmost navbar toggle -->
      <div *ngIf="isMobile" class="logo-right" fxFlex="10" fxLayout="row" fxLayoutAlign="end center">
        <a (click)="showSideNavbar()"><img class="hamburger" src="../../../../assets/image/navigation-bar/lnr-menu.svg" /></a>
      </div>

      <!-- right part of the header -->
      <div *ngIf="!isMobile" class="logo-right" fxFlex="90" fxLayout="row" fxLayoutAlign="end center">
        <!-- left part -->
        <div class="logo-right-container" fxFlex fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5%">
          <ng-container>
            <!--            <a class="logo-text" routerLink="/home" routerLinkActive="active"-->
            <!--              >關於我們</a-->
            <!--            >-->
            <div>
              <a class="logo-text" [matMenuTriggerFor]="planMenu">{{'nav.be_member' | translate}}</a>
              <mat-menu #planMenu="matMenu">
                <a mat-menu-item (click)="closeSideNavbar()" class="menu-text" routerLink="/plan/grade">{{'nav.membership_type' | translate}}</a>
                <a mat-menu-item (click)="closeSideNavbar()" class="menu-text" routerLink="/plan/outline">{{'nav.membership_card' | translate}}</a>
              </mat-menu>
            </div>
            <div>
              <a class="logo-text" [matMenuTriggerFor]="membershipMenu">{{'nav.membership' | translate}}</a>
              <mat-menu #membershipMenu="matMenu">
                <a mat-menu-item (click)="closeSideNavbar()" class="menu-text" routerLink="/membership/credit">{{'nav.membership_point' | translate}}</a>
                <a mat-menu-item (click)="closeSideNavbar()" class="menu-text" routerLink="/membership/event">{{'nav.membership_offer' | translate}}</a>
              </mat-menu>
            </div>
            <div>
              <a class="logo-text" [matMenuTriggerFor]="eventMenu">{{'nav.activity' | translate}}</a>
              <mat-menu #eventMenu="matMenu">
                <a mat-menu-item (click)="closeSideNavbar()" class="menu-text" routerLink="/event/join">{{'nav.join_activity' | translate}}</a>
                <a mat-menu-item (click)="closeSideNavbar()" class="menu-text" routerLink="/event/gallery">{{'nav.activity_history' | translate}}</a>
              </mat-menu>
            </div>
            <a class="logo-text" routerLink="/partner" routerLinkActive="active">{{'nav.partnership' | translate}}</a>
            <a class="logo-text" routerLink="/newsletter" routerLinkActive="active">{{'nav.news' | translate}}</a>
          </ng-container>
        </div>
        <!-- right part  -->
        <div class="logo-rightmost-container" fxLayout="row" fxLayoutAlign="end center">
          <div class="gold-side-bar"></div>
          <img class="registration-logo" src="../../../../assets/image/navigation-bar/-e-account.png" routerLink="/member" />
          <div class="gold-register">
            <div class="lang-selector" (click)="switchLang('tc')" [ngStyle]="{'color': currentLang=='tc' ? '#f4e48c' : '#a38a45' }">繁</div>
            <div class="separator">|</div>
            <div class="lang-selector" (click)="switchLang('sc')" [ngStyle]="{'color': currentLang=='sc' ? '#f4e48c' : '#a38a45' }">简</div>
            <div class="separator">|</div>
            <div class="lang-selector" (click)="switchLang('en')" [ngStyle]="{'color': currentLang=='en' ? '#f4e48c' : '#a38a45' }">EN</div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
<div class="gold-bottom-bar"></div>

import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-partner-overview',
  templateUrl: './partner-overview.component.html',
  styleUrls: ['./partner-overview.component.scss'],
})
export class PartnerOverviewComponent implements OnInit {

  @Input() image: string;
  @Input() title: string;
  @Input() detail: string[];
  @Input() backGroundColor: string;
  @Input() hoverTitle: string;
  @Input() hoverDetail: string[];
  @Input() knowMoreText: string;
  @Input() knowMoreLink: string;

  constructor() {}

  ngOnInit(): void {

  }
}

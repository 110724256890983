import { Injectable } from '@angular/core';
import { Strength } from './app.type';

@Injectable({
  providedIn: 'root',
})
export class StrengthsService {
  private strengths = [
    {
      title: 'home.strength_service_title_3',
      content: [
        'home.strength_service_title_3_content_1',
        'home.strength_service_title_3_content_2',
        'home.strength_service_title_3_content_3',
        'home.strength_service_title_3_content_4',
      ],
      bgUrl:
        '../assets/image/home-page/home_yoga.jpg',
    },
    {
      title: 'home.strength_service_title_2',
      content: [
        'home.strength_service_title_2_content_1',
        'home.strength_service_title_2_content_2',
        'home.strength_service_title_2_content_3',
        'home.strength_service_title_2_content_4',
    ],
      bgUrl:
        '../assets/image/home-page/home_tech.jpg',
    },
    {
      title: 'home.strength_service_title_1',
      content: [
        'home.strength_service_title_1_content_1',
        'home.strength_service_title_1_content_2',
        'home.strength_service_title_1_content_3',
        'home.strength_service_title_1_content_4',
        'home.strength_service_title_1_content_9',
        'home.strength_service_title_1_content_5',
        'home.strength_service_title_1_content_6',
        'home.strength_service_title_1_content_7',
        'home.strength_service_title_1_content_8',
      ],
      bgUrl:
        '../assets/image/home-page/home_whisky.jpg',
    },
    
  ];
  constructor() {}
  getStrengths(): Strength[] {
    return this.strengths;
  }
}

import {AfterViewInit, Component, OnInit,} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-partner-page',
  templateUrl: './partner-page.component.html',
  styleUrls: ['./partner-page.component.scss'],
})
export class PartnerPageComponent implements OnInit{


  constructor() {
  }

  ngOnInit(): void {

  }




}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public play: boolean = false;
  @ViewChild('video')
  video: ElementRef;

  constructor() {}

  ngOnInit(): void {
    setTimeout(() => {
      const videoEle = this.video?.nativeElement;
      if (videoEle) {
        this.play = true;
        videoEle.play();
      }
    }, 500);
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent implements OnInit {

  isLoadMore: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  loadmore(){
    this.isLoadMore = !this.isLoadMore;
    console.log(this.isLoadMore);
    
  }

}

<section id="strength-container">
  <h1 class="title darkGold bold-char" appAnimationOnScroll [type]="'fadeInBottom'" [mode]="'700ms ease-out'">{{'home.strength_title_1' | translate}}</h1>
  <h2 class="title darkGold" appAnimationOnScroll [type]="'fadeInBottom'" [mode]="'700ms ease-out'">{{'home.strength_title_1_content_1' | translate}}</h2>
  <h2 class="title darkGold bottom-103" appAnimationOnScroll [type]="'fadeInBottom'" [mode]="'700ms ease-out'">{{'home.strength_title_1_content_2' | translate}}</h2>
  
  <h1 class="title darkGold">{{'home.strength_title_2' | translate}}</h1>
  <div class="content bottom-103" appAnimationOnScroll [type]="'fadeInBottom'" [mode]="'700ms ease-out'">
    <h2>{{'home.strength_title_2_content_1' | translate}}</h2>
    <h2>{{'home.strength_title_2_content_2' | translate}}</h2>
  </div>
  <h1 class="title darkGold">{{'home.strength_title_3' | translate}}</h1>
  <div class="content bottom-103" appAnimationOnScroll [type]="'fadeInBottom'" [mode]="'700ms ease-out'">
    <h2>{{'home.strength_title_3_content_1' | translate}}</h2>
   </div>

  <div id="grid-container">
    <div class="app-strength" [strength]="s" *ngFor="let s of strengths" appAnimationOnScroll [type]="'fadeInBottom'" [mode]="'700ms ease-out'"></div>
  </div>



</section>
<div class="event-detail-sub-container">
  <div class="event-images">
    <div class="product-image-container">
      <img src="{{ currentImageUrl }}" alt="" class="product-image" />
      <div fxLayout="row" class="carousel-container">
        <div fxFlex="100" class="carousel-elements">
          <carousel #myCarousel="carousel" [height]="100" objectFit="contain" [images]="productImages" [cellsToShow]="3" arrowsOutside="false" (events)="handleCarouselEvents($event)">
          </carousel>
        </div>
      </div>
    </div>
  </div>
  <div class="event-detail-sub-left">
    <div class="golden-flag">
      <div class="img-parent">
        <img src="assets/image/shared/gold-flag_2.png" class="top-left-img" alt="" />
      </div>
      <div class="flag-in-text">
        <div class="main">{{ title | translate}}</div>
      </div>
    </div>
    <div class="text-container">
      <div class="detail" *ngFor="let text of detail">{{ text | translate}}</div>
      <div class="event-date">{{'event.date' | translate}}: {{ eventDate }}</div>
    </div>
  </div>
</div>
<div class="newsletter-main-container">
    <div class="newsletter-overview-container">
        <div class="newsletter-title-container">
            <h1 class="title">{{'newsletter.title_1' | translate}}</h1>
        </div>
        <!-- <hr> -->
        <div class="four-column-container">
            <div class="column">
                <a href="assets/image/newsletter/2024/KPC_Newsletter_TC_2024_Vol_1.pdf" target="_blank">
                    <img src="assets/image/newsletter/2024/KPC_Newsletter_TC_2024_Vol_1.jpg" alt="" />
                </a>
            </div>
            <div class="column">
                <a href="assets/image/newsletter/2023/KPC_Newsletter_TC_2023_Vol_1.pdf" target="_blank">
                    <img src="assets/image/newsletter/2023/KPC_Newsletter_TC_2023_Vol_1.jpg" alt="" />
                </a>
            </div>
            <div class="column">
                <a href="assets/image/newsletter/2023/KPC_Newsletter_TC_2023_Vol_2.pdf" target="_blank">
                    <img src="assets/image/newsletter/2023/KPC_Newsletter_TC_2023_Vol_2.jpg" alt="" />
                </a>
            </div>
            <div class="column">
                <a href="assets/image/newsletter/2023/KPC_Newsletter_TC_2023_Vol_3.pdf" target="_blank">
                    <img src="assets/image/newsletter/2023/KPC_Newsletter_TC_2023_Vol_3.jpg" alt="" />
                </a>
            </div>
            
        </div>
        <div *ngIf="isLoadMore===true" class="four-column-container">   
            <div class="column">
                <a href="assets/image/newsletter/2022/KPC_2022Q1_Newsletter_TC.pdf" target="_blank">
                    <img src="assets/image/newsletter/2022/KPC_2022Q1_Newsletter_TC_1.png" alt="" />
                </a>
            </div>
            <div class="column">
                <a href="assets/image/newsletter/2022/KPC_2022Q2_Newsletter_TC.pdf" target="_blank">
                    <img src="assets/image/newsletter/2022/KPC_2022Q2_Newsletter_TC_1.png" alt="" />
                </a>
            </div>
            <div class="column">
                <a href="assets/image/newsletter/2022/2022_3rd_Newsletter_TC.pdf" target="_blank">
                    <img src="assets/image/newsletter/2022/2022_3rd_Newsletter_TC_1.png" alt="" />
                </a>
            </div> 
            <div class="column">
                <a href="assets/image/newsletter/2021/2021_Newsletter_issue 1_KPC_TC.pdf" target="_blank">
                    <img src="assets/image/newsletter/2021/2021_Newsletter_issue_1.jpg" alt="" />
                </a>
            </div>
            <div class="column">
                <a href="assets/image/newsletter/2021/2021_Newsletter_issue 2_KPC_TC.pdf" target="_blank">
                    <img src="assets/image/newsletter/2021/2021_Newsletter_issue_2.jpg" alt="" />
                </a>
            </div>
            <div class="column">
                <a href="assets/image/newsletter/2021/2021_Newsletter_issue 3_KPC_TC.pdf" target="_blank">
                    <img src="assets/image/newsletter/2021/2021_Newsletter_issue_3.jpg" alt="" />
                </a>
            </div>
            <div class="column">
                <a href="assets/image/newsletter/2020/KPC_Newsletter_TC-1-3.pdf"  target="_blank">
                <img src="assets/image/newsletter/2020/2020_Newsletter_issue_1.jpg" alt="" />
                </a>
            </div>
            <div class="column">
                <a href="assets/image/newsletter/2020/2020_Newsletter_issue 2_KPC_TC.pdf"  target="_blank">
                <img src="assets/image/newsletter/2020/2020_Newsletter_issue_2.jpg" alt="" />
                </a>
            </div>
            <div class="column">
                <a href="assets/image/newsletter/2020/2020_Newsletter_issue 3_KPC_TC.pdf"  target="_blank">
                <img src="assets/image/newsletter/2020/2020_Newsletter_issue_3.jpg" alt="" />
                </a>
            </div>
            <div class="column">
                <a href="assets/image/newsletter/2020/2020_Newsletter_issue 4_KPC_TC.pdf"  target="_blank">
                <img src="assets/image/newsletter/2020/2020_Newsletter_issue_4.jpg" alt="" />
                </a>
            </div>
        </div>
        <div class="loadmore_container">
            <button class="newsletter_button" (click)="loadmore()">
                <span *ngIf="isLoadMore===false">{{'newsletter.button_1' | translate}}</span>
                <span *ngIf="isLoadMore===true">{{'newsletter.button_2' | translate}}</span>
            </button>
        </div>
    </div>
</div>
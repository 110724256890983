import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-full-wide-header-text',
  templateUrl: './full-wide-header-text.component.html',
  styleUrls: ['./full-wide-header-text.component.scss'],
})
export class FullWideHeaderTextComponent implements OnInit {

  @Input() title: string;
  @Input() detail: string;


  constructor() {
  }

  ngOnInit(): void {
  }

}

<footer class="kpc-video-container">
  <!-- <div class="kpc-video-desc">
    <span>享受尊貴生活</span> <span>盡在生活會</span>
  </div> -->
  <video #video autoplay loop [muted]="true">
    <source src="/assets/video/KPC_Video.mp4" type="video/mp4"/>
    Your browser does not support the video tag.
  </video>
  <!-- <a href="/#/home-page">
    <img class="play" src="../../../../assets/image/home-page/-e-play-button.png" alt="play" />
  </a> -->
</footer>

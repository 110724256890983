import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from './views/home-page/home-page.component';
import { PartnerPageComponent } from './views/partner-page/partner-page.component';
import { PartnerDetailComponent } from './views/partner-detail/partner-detail.component';
import { PrivacyPolicyComponent } from './views/privacy-policy/privacy-policy.component';
import { TncComponent } from './views/tnc/tnc.component';
import { DataPolicyComponent } from './views/data-policy/data-policy.component';
import { NewsletterComponent } from './views/newsletter/newsletter.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    component: HomePageComponent,
    loadChildren: () =>
      import('./views/home-page/home-page.module').then(
        (m) => m.HomePageModule
      ),
    data: { preload: true },
  },
  {
    path: 'plan',
    loadChildren: () =>
      import('./views/plan-page/plan-page.module').then(
        (m) => m.PlanPageModule
      ),
  },
  {
    path: 'membership',
    loadChildren: () =>
      import('./views/membership-page/membership-page.module').then(
        (m) => m.MembershipPageModule
      ),
  },
  {
    path: 'event',
    loadChildren: () =>
      import('./views/event-page/event-page.module').then(
        (m) => m.EventPageModule
      ),
  },
  {
    path: 'partner',
    component: PartnerPageComponent,
    loadChildren: () =>
      import('./views/partner-page/partner-page.module').then(
        (m) => m.PartnerPageModule
      ),
  },
  {
    path: 'partner-detail/:id',
    component: PartnerDetailComponent,
    loadChildren: () =>
      import('./views/partner-detail/partner-detail.module').then(
        (m) => m.PartnerDetailModule
      ),
  },
  {
    path: 'member',
    loadChildren: () =>
      import('./views/member-page/member.module').then((m) => m.MemberModule),
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
    loadChildren: () =>
      import('./views/privacy-policy/privacy-policy.module').then(
        (m) => m.PrivacyPolicyModule
      ),
  },
  {
    path: 'tnc',
    component: TncComponent,
    loadChildren: () =>
      import('./views/tnc/tnc.module').then(
        (m) => m.TncModule
      ),
  },
  {
    path: 'data-policy',
    component: DataPolicyComponent,
    loadChildren: () =>
      import('./views/data-policy/data-policy.module').then(
        (m) => m.DataPolicyModule
      ),
  },
  {
    path: 'newsletter',
    component: NewsletterComponent,
    loadChildren: () =>
      import('./views/newsletter/newsletter.module').then(
        (m) => m.NewsletterModule
      ),
  },
  {
    path: '**',
    redirectTo: 'home',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
